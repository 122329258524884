.timer-countdown {
    .interval {
        font-size: 0.825rem;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .times-wrapper {
        width: 4.562rem;
        height: 5.0625rem;
        background: rgba(255, 255, 255, 0.25);
        opacity: 0.8;
        border: 1px solid rgba(255, 255, 255, 0.8);
        box-sizing: border-box;
        backdrop-filter: blur(16px);
        border-radius: 0.625rem;
        font-weight: 800;
        font-size: 2.5rem;
        line-height: 5rem;
        text-align: center;
        color: #ffffff;
    }
}
