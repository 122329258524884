.background-layer {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.content-layer {
    position: relative;
}

.layer-1 {
    position: absolute;
    z-index: 0;
}

.layer-2 {
    position: absolute;
    z-index: 1;
}

.layer-3 {
    position: absolute;
    z-index: 2;
}

.layer-4 {
    position: absolute;
    z-index: 3;
}

.layer-5 {
    position: absolute;
    z-index: 4;
}



.vector-1 {
    width: 38%;
    padding-top: 56%;
    left: 0;
    top: 0;
    background:  url("/assets/background/vector/hg-background-vector-1.svg")
        center no-repeat;
    background-size: cover;
}

.vector-2 {
    width: 36%;
    padding-top: 70%;
    right: 0;
    top: 160.82vh;
    background: url("/assets/background/vector/hg-background-vector-2.svg")
        right no-repeat;
    background-size: contain;
}

.vector-3 {
    width: 100%;
    padding-top: 84%;
    right: 0;
    left: 0;
    top: 248.41vh;
    background: url("/assets/background/vector/hg-background-vector-3.svg")
        center no-repeat;
    background-size: contain;
}

.vector-4 {
    padding-top: 35%;
    width: 100%;
    right: 0;
    left: 0;
    top: 468vh;
    background: url("/assets/background/vector/hg-background-vector-4.svg")
        center no-repeat;
    background-size: contain;
}

.vector-5 {
    padding-top: 40%;
    width: 43%;
    left: 0;
    bottom: 0;
    background: url("/assets/background/vector/hg-background-vector-5.svg")
        center no-repeat;
    background-size: contain;
}

.vector-6 {
    padding-top: 50%;
    width: 73%;
    right: 0;
    bottom: 0;
    background: url("/assets/background/vector/hg-background-vector-6.svg")
        center no-repeat;
    background-size: contain;
}

.vector-7 {
    padding-top: 51%;
    width: 42%;
    left: 0;
    top: 563vh;
    background: url("/assets/background/vector/hg-background-vector-7.svg")
        center no-repeat;
    background-size: contain;
}

.vector-8 {
    padding-top: 69%;
    width: 100%;
    right: 0;
    bottom: 245vh;
    background: url("/assets/background/vector/hg-background-vector-8.svg")
        center no-repeat;
    background-size: contain;
}

.vector-9 {
    padding-top: 87%;
    width: 100%;
    left: 0;
    bottom: 115vh;
    background: url("/assets/background/vector/hg-background-vector-9.svg")
        center no-repeat;
    background-size: contain;
}

.vector-10 {
    padding-top: 20%;
    width: 20%;
    left: 0;
    bottom: 50vh;
    background: url("/assets/background/vector/hg-background-vector-10.svg")
        center no-repeat;
    background-size: contain;
}

.promo-vector-1 {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background:  url("/assets/promo-background/vector/layer1.svg") no-repeat;
    background-size: contain;
}
.promo-vector-2 {
    width: 100%;
    height: 100%;
    left: 0;
    //top: 0;
    background:  url("/assets/promo-background/vector/layer2.svg") no-repeat;
    background-size: contain;
    background-position-y: center;
}

.promo-vector-3 {
    width: 100%;
    height: 100%;
    left: 0;
    //top: 0;
    background:  url("/assets/promo-background/vector/layer3.svg") no-repeat;
    background-size: contain;
    background-position-y: bottom;
}

.hero-image {
    background-image: url("/assets/promo-background/img/hero.png");
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
     width: 100%;
     height: 100%;
}

.jellyfish {
    width: 42%;
    padding-top: 50%;
    right: 15%;
    top: 2%;
    background: url("/assets/background/img/jellyfish-large.png") center
        no-repeat;
    background-size: cover;
    background-blend-mode: lighten;
}

.diffused-circle-1 {
    padding-top: 59%;
    width: 50%;
    top: 0;
    right: 0;
    background: url("/assets/background/img/hg-background-diffused-1.png") right
        no-repeat;
    background-size: contain;
}

.diffused-circle-2 {
    padding-top: 80%;
    width: 80%;
    top: 120vh;
    left: 0;
    background: url("/assets/background/img/hg-background-diffused-2.png")
        center no-repeat;
    background-size: contain;
}

.diffused-circle-3 {
    padding-top: 80%;
    width: 80%;
    top: 280vh;
    right: 0;
    background: url("/assets/background/img/hg-background-diffused-3.png") right
        no-repeat;
    background-size: contain;
}

.diffused-circle-4 {
    padding-top: 80%;
    width: 80%;
    top: 360vh;
    left: 0;
    background: url("/assets/background/img/hg-background-diffused-4.png") left
        no-repeat;
    background-size: contain;
}

.diffused-circle-5 {
    padding-top: 80%;
    width: 80%;
    top: 0;
    left: 0;
    background: url("/assets/background/img/hg-background-diffused-5.png") left
        no-repeat;
    background-size: contain;
}

.diffused-circle-6 {
    padding-top: 48%;
    width: 37%;
    top: 550vh;
    right: 0;
    background: url("/assets/background/img/hg-background-diffused-6.png")
        center no-repeat;
    background-size: contain;
}

.diffused-circle-7 {
    padding-top: 80%;
    width: 80%;
    top: 620vh;
    left: 0;
    background: url("/assets/background/img/hg-background-diffused-7.png") left
        no-repeat;
    background-size: contain;
}

.diffused-circle-8 {
    padding-top: 70%;
    width: 70%;
    top: 630vh;
    right: 5vw;
    background: url("/assets/background/img/hg-background-diffused-8.png")
        center no-repeat;
    background-size: contain;
}

// bubble-sm

@mixin bubble-sm-list {
    @for $i from 1 through 13 {
        .bubble-sm-#{$i} {
            height: 0.5rem;
            width: 0.5rem;
            background: url(/assets/background/img/hg-background-ellipse-1-#{$i}.png)
                center
                no-repeat;
            background-size: contain;
        }
    }
}

// bubble-md

@mixin bubble-md-list {
    @for $i from 1 through 5 {
        .bubble-md-#{$i} {
            height: 1rem;
            width: 1rem;
            background: url(/assets/background/img/hg-background-ellipse-2-#{$i}.png)
                center
                no-repeat;
            background-size: contain;
        }
    }
}

// bubble-lg

@mixin bubble-lg-list {
    @for $i from 1 through 8 {
        .bubble-lg-#{$i} {
            height: 1.5rem;
            width: 1.5rem;
            background: url(/assets/background/img/hg-background-ellipse-3-#{$i}.png)
                center
                no-repeat;
            background-size: contain;
            transform: translate(-10vw, -10vh);
        }
    }
}

//positioning

@mixin r-list {
    @for $i from 1 through 50 {
        .r-#{$i} {
            right: ((random(90) + 3)) + vw;
        }
    }
}

@mixin l-list {
    @for $i from 1 through 50 {
        .l-#{$i} {
            left: ((random(90) + 3)) + vw;
        }
    }
}

@mixin t-list {
    @for $i from 1 through 100 {
        .t-#{$i} {
            top: (random(950)) + vh;
        }
    }
}

@include bubble-sm-list();
@include bubble-md-list();
@include bubble-lg-list();
@include r-list();
@include l-list();
@include t-list();
