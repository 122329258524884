.content-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.padded-y {
        padding-top: 5rem;
        padding-bottom: 5rem;

        @media (max-width: 768px) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }
}

.auth-layout {
    min-height: 100vh;
    background-image: url("/assets/background/vector/auth-bg.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .left-grid {
        position: relative;
        .main-img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        .content {
            position: absolute;
            left: 200px;
            top: -150px;
            h3 {
                font-weight: 600;
                letter-spacing: 1px;
            }
        }

        .sub-content {
            position: absolute;
            left: 270px;
            top: -10px;

            .sub-content-query {
                color: #87868a;
                font-weight: bold;
            }

            .blue-link {
                color: #00a3ff;
                text-shadow: 0 0 2px #00a3ff;
            }

            .red-link {
                color: #ff005c;
                text-shadow: 0 0 2px #ff005c;
            }
        }
    }
    .toggle-link {
        font-size: 18px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }

    .auth-title {
        letter-spacing: 2px;
    }

    .sub-title {
        color: #9f9f9f;
        font-size: 13px;
        letter-spacing: 2px;
    }

    .froget-password {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
    }

    .green-link {
        color: #00ffa3;
        text-shadow: 0 0 2px #00ffa3;
    }

    .white-link {
        color: #fff;
        text-shadow: 0 0 2px #fff;
    }

    .verify-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.landing-page-content {
    .hero-wrapper {
        height: 100vh;
        position: relative;

        @media (max-width: 1199px) {
            height: 69vh;
        }

        @media (max-width: 992px) {
            height: 63vh;
        }

        @media (max-width: 768px) {
            height: 65vh;
        }

        @media (max-width: 526px) {
            height: 60vh;
        }

        @media (max-width: 399px) {
            height: 70vh;
        }

        .hero-inner {
            height: 28vw;

            @media (min-width: 1200px) {
                height: 31vw;
            }

            @media (min-width: 1550px) {
                height: 32vw;
            }

            @media (max-width: 924px) {
                height: 30vh;
            }

            @media (max-width: 526px) {
                height: 32vh;
            }
        }

        .bottom-text {
            position: absolute;
            bottom: 0rem;
            width: 100%;
        }

        .countdown-row {
            @media (min-width: 1199px) {
                margin-top: -2rem;
            }
        }
    }

    .bid-details-wrapper {
        .current-bid {
            background: rgba(255, 255, 255, 0.25);
            backdrop-filter: blur(16px);
            border-radius: 10px;
            height: 72px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 30px;
        }
    }

    .hero-banner {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .artist-details {
            width: 20% !important;
        }
    }

    .caption-sm {
        font-size: 70%;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.3em;
        color: #9f9f9f;
    }
    .heading-container {
        //padding: 10vh 10vw;
        .heading-xxl {
            font-size: 325.5%;
            line-height: 135%;
        }
        .heading-xl {
            font-size: 262.5%;
            line-height: 115%;

            @media (max-width: 526px) {
                font-size: 200%;
                line-height: 115%;
            }
        }
        .heading-lg {
            font-size: 200%;
            line-height: 150%;
        }
        .heading-md {
            //padding: 1.25rem 10%;
            font-size: 175%;
            line-height: 150%;
        }
        .heading-sm {
            //padding: 1.25rem 10%;
            font-size: 150%;
            line-height: 150%;
        }

        .heading-root {
            //padding: 1.25rem 10%;
            font-size: 100%;
            line-height: 150%;
        }
        .heading-xs {
            // padding: 1rem 10%;
            font-size: 87.5%;
            line-height: 150%;
        }
    }
    .scroll-down-indicator {
        border: none;
        outline: none;
        height: 2rem;
        width: 2rem;
        text-indent: -999em;
        background: url("/assets/icon/chevron-circle-down-light.svg") center
            no-repeat;
        background-size: contain;
    }
    .cards-container {
        width: 100%;
        padding: 0 10vw;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .bid-step-details {
        // width: 80%;
        // padding: 8rem 0;
        // display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        @media (min-width: 768px) {
            margin-top: 5.5rem;
            margin-bottom: 5.5rem;
        }

        .content {
            padding: 0 5%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (min-width: 768px) {
                padding: 0;
            }

            &.content-text {
                width: 100%;
                align-items: flex-start;
            }
            &.content-card {
                width: 100%;
                max-width: 400px;
                margin: auto;
                align-items: flex-end;

                @media (min-width: 1549px) {
                    max-width: 500px;
                }

                // hide on md
                @media (max-width: 1024px) {
                    max-width: 250px;
                }

                @media (max-width: 767px) {
                    display: none;
                }
            }
            .bid-card-container {
                position: relative;
                padding-top: 120%;
                width: 100%;
                &.rotated-cw {
                    transform: rotate(25.63deg);
                }
                &.rotated-ccw {
                    transform: rotate(-31.46deg);
                }
                .bid-card {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 0.625rem;
                    background: center no-repeat;
                    // backdrop-filter: blur(33px);
                    background-size: contain;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-size: contain;
                        background: transparent center no-repeat;
                    }

                    .badge-card {
                        position: absolute;
                        height: 33.75%;
                        width: 40%;
                        border-radius: 0.625rem;
                        background: rgba(255, 255, 255, 0.42) center no-repeat;
                        // backdrop-filter: blur(33px);
                    }

                    &.step-1 {
                        background-image: url("/assets/icon/step-1-vector.svg");
                        &::before {
                            background-image: url("/assets/icon/step-1-vector.svg");
                            filter: blur(22px);
                        }
                        .badge-card {
                            top: 0;
                            right: 0;
                            transform: rotate(-20.99deg) translate(50%, -10%);
                            background-image: url("/assets/icon/step-1-badge.svg");
                            background-size: 60%;
                        }
                    }

                    &.step-2 {
                        background-image: url("/assets/icon/step-2-vector.svg");
                        &::before {
                            background-image: url("/assets/icon/step-2-vector.svg");
                            filter: blur(22px);
                        }
                        .badge-card {
                            bottom: 0;
                            right: 0;
                            transform: rotate(23.17deg) translate(40%, 25%);
                            background-image: url("/assets/icon/step-2-badge.svg");
                            background-size: 60%;
                        }
                    }

                    &.step-3 {
                        background-image: url("/assets/icon/step-3-vector.svg");
                        &::before {
                            background-image: url("/assets/icon/step-3-vector.svg");
                            filter: blur(22px);
                        }
                        .badge-card {
                            bottom: 0;
                            right: 0;
                            transform: rotate(-40.4deg) translate(8%, 58%);
                            background-image: url("/assets/icon/step-3-badge.svg");
                            background-size: 60%;
                        }
                    }

                    &.payment {
                        height: 80%;
                        background-image: url("/assets/icon/payment-options.svg");
                        transform: rotate(-10deg);
                        &::before {
                            background-image: url("/assets/icon/payment-options.svg");
                            filter: blur(22px);
                        }
                        .badge-card {
                            bottom: 0;
                            right: 0;
                            height: 40%;
                            transform: rotate(-40.4deg) translate(8%, 58%);
                            background-image: url("/assets/icon/crypto-symbol.svg");
                            background-size: 100%;
                        }
                    }
                }

                .bid-card-bg {
                    background: rgba(255, 255, 255, 0.25) center no-repeat;
                }
            }
            .step-text {
                font-weight: 600;
                font-size: 137%;
                line-height: 150%;
                margin-bottom: 1rem;
            }
            .title {
                font-weight: bold;
                font-size: 200%;
                line-height: 118%;
                margin-bottom: 2rem;

                @media (max-width: 768px) {
                    margin-bottom: 1rem;
                }
            }
            .subtitle {
                font-size: 100%;
                line-height: 150%;
            }
        }

        .promo-images {
            .img-left {
                border-radius: 10px;
                //transform: rotate(-6.21deg);
            }

            .img-right {
                border-radius: 10px;
                position: absolute;
                z-index: -1;
                transform: translateX(-70%) rotate(1deg);
                top: 0;
            }
        }
    }
    .register-form-container {
        // padding: 5vh 5vw;
        .register-form {
            //display: flex;
            justify-content: space-around;
        }
    }
    .featured-art-container {
        width: 80%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .title-bold {
            font-weight: bold;
            font-size: 200%;
            line-height: 150%;
        }

        .bidding-summary {
            width: 100%;
            padding-top: 1.75rem;
            display: flex;
            flex-wrap: wrap;

            .summary-info {
                flex: 0 0 50%;
                max-width: 50%;

                .label {
                    font-size: 100%;
                    line-height: 50%;
                }

                .value {
                    font-weight: bold;
                    font-size: 150%;
                    line-height: 150%;
                }
            }
        }

        .art-img-container {
            display: flex;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .art-img-wrapper {
                position: relative;
                padding-top: 100%;
                width: 100%;
                .art-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    .art-container {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        .title-container {
            max-width: 40rem;
        }

        .art-card {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2.5rem 1rem;
        }
    }
    .footer {
        width: 100%;
        .ribbon {
            height: 12px;
            width: 100%;
            background: linear-gradient(
                90.03deg,
                #ff00a8 -0.1%,
                #6577d4 54.28%,
                #00ffa3 99.34%
            );
        }
        .footer-content {
            width: 100%;
            padding: 4vw;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            background: rgba(255, 255, 255, 0.12);
            // backdrop-filter: blur(16px);
            .org-info {
                padding: 3vw;
                .logo-container {
                    display: flex;
                    .logo-text {
                        padding-left: 1rem;
                        .title {
                            font-size: 1.5rem;
                            font-weight: 700;
                            line-height: 1.5rem;
                        }
                        .subtitle {
                            font-size: 0.8rem;
                            font-weight: 500;
                            line-height: 1.5rem;
                        }
                    }
                }
                .copyright-info {
                    padding: 10% 0;
                    font-size: 75%;
                }

                .social-buttons {
                    display: flex;
                    .social-button {
                        width: 2.25rem;
                        height: 2.25rem;
                        margin-right: 0.5rem;
                        color: #fff;
                        background: transparent;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .more-info {
                display: flex;
                color: #ffffff;
                padding: 3vw 2vw;
                .link {
                    color: #ffffff;
                    display: block;
                }
                .title {
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: 0.18em;
                }
                .site-info {
                    padding: 0 1vw;
                    font-size: 14px;
                    line-height: 41px;
                    display: block;
                }
                .policy-info {
                    padding: 0 1vw;
                    font-size: 14px;
                    line-height: 41px;
                    display: block;
                }
            }
        }
    }

    // Elements

    .info-title {
        font-weight: 600;
        font-size: 137%;
        line-height: 150%;
        color: rgba(255, 255, 255, 0.64);
    }

    .divider {
        width: 100%;
        margin: 0.4rem 0;
        border-top: 2px solid rgba(255, 255, 255, 0.64);
    }

    .chip-container {
        display: inline-block;
        background: #3a415b;
        border-radius: 50rem;
        .chip {
            .chip-icon {
                border: 1px solid transparent;
                border-radius: 50%;
                height: 2.5rem;
                width: 2.5rem;
                margin-right: 0.5rem;
            }
            display: flex;
            align-items: center;
            height: 2.5rem;
            padding-right: 1rem;
        }
    }
}

.bid-carousel-section {
    .title-text {
        font-size: 150%;
        color: rgba(255, 255, 255, 0.64);
        padding-bottom: 0.5rem;
        border-bottom: 2px solid rgba(255, 255, 255, 0.64);
    }

    .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .slick-prev {
        background-image: url(/assets/icon/arrow-left.svg) !important;

        @media (min-width: 768px) {
            left: -50px;
        }
    }

    .slick-next {
        background-image: url(/assets/icon/arrow-right.svg) !important;

        @media (min-width: 768px) {
            right: -50px;
        }
    }

    .slick-prev,
    .slick-next {
        background-size: 25px !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        width: 50px;
        height: 50px;
        transform: translate(0, -40%) !important;
    }
}
