.ArtCardItem {
    padding: 1rem;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(16px);
    border-radius: 0.625rem;
    width: 18.5rem;
    margin: auto;
    
    .card-img-wrapper {
        position: relative;
        width: 100%;
        padding-top: 62%;
        .card-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
        .card-badge {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 2.25rem;
            height: 2.25rem;
            transform: translate(25%, 50%);
        }
    }
}

.ArtCardContent {
    margin-top: 1.25rem;
    .ArtCardName {
        font-weight: bold;
        font-size: 125%;
        line-height: 150%;
    }
    .ArtCardBid {
        font-weight: normal;
        font-size: 87.5%;
        line-height: 150%;
        color: #ffffff;
    }
    .ArtCard-Footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer-section {
            width: 50%;
            .ArtCardMoneyRate {
                font-weight: bold;
                font-size: 137%;
                line-height: 150%;
                color: #ffffff;
            }
            .ArtCardBtn {
                font-weight: 600;
                letter-spacing: 0.14em;
                padding: 0.75rem 0.9rem;
                color: #fff;
                border: none;
                outline: none;
                border-radius: 1px;
                background: linear-gradient(
                    93.15deg,
                    #0cecfc -13.54%,
                    #fa10f7 56.57%,
                    #186ee3 115.93%
                );
            }
        }
    }
}

