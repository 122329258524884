.artist-details {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &.content-text {
            width: 100%;
            align-items: flex-start;
        }
        &.content-card {
            width: 100%;
            align-items: flex-end;
            max-width: 255px;

            // hide on md
            @media (max-width: 768px) {
                display: none;
            }
        }
        .artist-card-container {
            position: relative;
            padding-top: 115%;
            width: 95%;
            .artist-card-ghost {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 50%;
                transform: rotate(-2.17deg);
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 40%;
                    height: 40%;
                    background: url("/assets/icon/artist-ghost-background.svg")
                        center no-repeat;
                    background-size: contain;
                    transform: translate(-30%, -40%) rotate(2.17deg);
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 0.625rem;
                    background: linear-gradient(
                        121.93deg,
                        #ff005c 9.76%,
                        #00d8ff 88.92%
                    );
                }
            }
            .artist-card {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 0.625rem;
                transform: rotate(5.55deg);
            }
            // rotate(#{$i * -12.08}deg)
            @for $i from 1 through 10 {
                .artist-card-thumb:nth-child(#{$i}n) {
                    transform: translate(#{-350% + $i*90%}, -10%) rotate(#{-50 + $i*10}deg);
                    animation-delay: #{$i * 0.5}s;
                }
            }

            .artist-card-thumb {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 40%;
                height: 40%;
                object-fit: contain;
                // &.thumb-1 {
                //     transform: translate(-60%, 10%) rotate(-12.08deg);
                // }
                // &.thumb-2 {
                //     transform: rotate(-3.56deg) translate(0, 5%);
                // }
                // &.thumb-3 {
                //     transform: translate(60%, 10%) rotate(12.08deg);
                // }
            }
        }
        .name-text {
            font-weight: bold;
            font-size: 200%;
            line-height: 118%;
            margin-bottom: 2rem;
        }
        .title {
            font-weight: 600;
            font-size: 137%;
            line-height: 150%;
        }
        .subtitle {
            font-size: 100%;
            line-height: 150%;
        }
        .info-container {
            display: block;
            .info-title {
                font-weight: bold;
                font-size: 137%;
                line-height: 150%;
            }
            .info-content {
                font-size: 162%;
                line-height: 142%;
            }
        }
    }
}
