.textfield-root {
    border: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(16px);
    border-radius: 2px;
    width: 100%;
    padding: 0.72rem 1.5rem;
    outline: none;

    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &:focus {
        outline: 1px solid #0cecfc;
    }

    &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    &::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }
}

.submit-btn {
    padding: 0.75rem 1.125rem;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 2px;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 1px;
    width: 150px;
    height: 48px;
    position: relative;
    background: linear-gradient(
        93.15deg,
        #0cecfc -13.54%,
        #fa10f7 56.57%,
        #186ee3 115.93%
    );

    .button-glow {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        border-radius: 1px;
        height: 100%;
        width: 100%;
    }

    &.w-gradient {
        .button-glow {
            background: linear-gradient(
                93.15deg,
                #0cecfc -13.54%,
                #fa10f7 56.57%,
                #186ee3 115.93%
            );
            filter: blur(15px);
        }
    }
}

.input-container {
    position: relative;

    .error-messages {
        position: absolute;
        pointer-events: none;
        top: 45%;
        transform: translateY(-50%);
        padding-right: 1.5rem;
        line-height: 1;
        text-align: right;
        color: #ff003d;
        font-weight: 500;
        font-size: 14px !important;
    }
}

.input-container:focus-within label {
    transform: translate(0, 4px) scale(0.8);
}

.input-container .filled {
    transform: translate(0, 4px) scale(0.8);
}

.input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 60%) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    left: 2.2rem;
    margin-bottom: 0;
}

.custom-checkbox {
    .custom-control-label {
        font-weight: normal;
        font-size: 14px;
        box-shadow: none !important;
        outline: none !important;
        padding-left: 0.75rem;

        &::before {
            border-radius: 3px;
            background-color: transparent;
            border: 2px solid rgba(255, 255, 255, 0.5);
            box-shadow: none !important;
            outline: none !important;
            width: 1.25rem;
            height: 1.25rem;
            top: 0.125rem;
        }

        &::after {
            width: 1.25rem;
            height: 1.25rem;
            top: 0.125rem;
        }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border: 2px solid rgba(255, 255, 255, 0.5);
        background-color: transparent;
        box-shadow: none;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("/assets/img/tick.svg");
    }
}

input:-webkit-autofill{
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

input:-webkit-autofill ~ label 
{
    transform: translate(0, 4px) scale(0.8);
}