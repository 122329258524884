.modal-container {

    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
 
    .modal-overlay {
       opacity: 0.28;
       background-color: #009aff;
       z-index: 500;
       position: fixed;
       left: 0;
       top: 0;
       height: 100vh;
       width: 100vw;
    }
 
    .modal-content {
       background-color: #ffffff;
       box-shadow: 0px 3px 6px #00000029;
       z-index: 1500;
       height: auto;
       max-height: 100vh;
       width: fit-content;
       overflow-x: auto;
    }
 }