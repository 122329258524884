.navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.25rem 1rem;
    z-index:100;

    .promo-logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -45%);

        @media (max-width: 768px) {
          display: none;
        }
    }

    .navbar-brand {
        box-shadow: unset;
        background: unset;
        padding: 0;
        .logo-container {
            display: flex;
            .logo-text {
                padding-left: 1rem;
                .title {
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 1.5rem;
                }
                .subtitle {
                    font-size: 0.8rem;
                    font-weight: 500;
                    line-height: 1.5rem;
                }
            }
        }
    }
    .navbar-collapse {
        z-index: 1;
    }
    .navbar-nav {
        .nav-item {
            letter-spacing: 2px;
            font-weight: 600;
            color: #fff;
            @media (min-width: 1199.98px) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
            @media (min-width: 1399.98px) {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }

        @media (max-width: 768px) {
            background-color: rgba(0, 0, 0, 0.9);
            padding: 20px 15px;
        }
    }

    .navbar-toggler {
        border: none;
        outline: none;
    }

    .auth-active,
    .active {
        color: #bd00ff !important;

        &::before {
            content: "";
            display: inline-block;
            width: 11.84px;
            height: 11.17px;
            border-radius: 50%;
            margin-right: 8px;
            background: #bd00ff;
            filter: blur(1px);
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }
    }
}
