.ImageButton-root {
    border: none;
    outline: none;
    background: transparent;
    .ImageButton-image {
        height: 2rem;
        width: 2rem;
    }
    &::before {
        content: '' !important;
    }
}