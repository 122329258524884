.card-wrapper {
    position: relative;
    padding-top: 20%;
    width: 25%;
    min-height: 14rem;
    min-width: 10rem;
    .card-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .icon-wrapper {
            position: relative;
            height: 2rem;
            width: 2rem;
            .icon {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .icon-glow {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                filter: blur(10px);
            }
        }

        .title {
            font-weight: 600;
            font-size: 95%;
            line-height: 120%;
            text-align: center;
            padding-top: 8%;
            padding-bottom: 5%;
        }
        .subtitle {
            font-size: 81.25%;
            line-height: 130%;
            text-align: center;
        }
    }
}
